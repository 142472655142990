<script setup lang="ts">
</script>
<template>
  <div class="logo">
    <NuxtLink to="/" class="">
        <img height="40" src="/images/logos/treelet_png_logo.png" />
      </NuxtLink>
    <!-- <h1 class="text-white">TREELET</h1> -->
  </div>
</template>
